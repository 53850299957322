<template>
  <div
      v-if="isOpen"
      class="modal fade show m-2"
      aria-labelledby="addNewCardTitle"
      style="display: block; background: #1c1d1c33"
  >
    <div class="width-95per bg-white mx-auto">
        <div class="card">
          <div style="background: #DBE7F2" class="p-2">
            <TitleButton
                :showBtn="true"
                :showSettingBtn="false"
                :showAddNew="false"
                :title="headerTitle"
                @onClickCloseButton="toggleModal()"
            />

            <div class="row mt-2">
              <div class="col-12 col-sm-6 col-md-6">
                <label class="align-middle">Supplier</label>
                <vField v-model="formData.supplier_id" class="d-none" name="supplier_id"/>
                <v-select
                    placeholder="Select Supplier"
                    v-model="formData.supplier_id"
                    :options="contacts"
                    label="name"
                    :reduce="name => name.id"
                    @option:selected="getAllPOBySupplierId"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="px-3">
            <div style="border-radius: 8px" class="row journal-line">
              <div class="col-12 d-flex product-details-border position-relative pe-0">
                <div class="row w-100 pe-lg-0 me-1 py-2">
                  <div class="col-md-12 col-12">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                        <tr>

                          <th>PO Date</th>
                          <th>PO No</th>
                          <th>PO Details</th>
                          <th>
                            <span v-if="poList.length">
                              <input type="checkbox" @click="selectAll" v-model="checkAll">
                            </span>
                            <span v-else>
                              #
                            </span>
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, i) in poList" :key="i">
                          <td>{{ item.date }}</td>
                          <td>{{ item.po_number }}</td>
                          <td>
                            <template v-if="item.purchase_general.length > 0">
                                <span v-for="(pog, i) in item.purchase_general">
                                  <span v-if="pog?.po_type === 'cs_po'">
                                      {{ pog.cs_general.requisition_general.product.name}} ({{ pog.quantity }} {{ pog.cs_general.requisition_general.product.description.unit.name}}),
                                  </span>

                                   <span v-if="pog.po_type === 'pr_po'">
                                      {{ pog.requisition_general ? pog.requisition_general.product.name : "N/A"}} ({{ pog.quantity }} {{ pog.requisition_general ? pog.requisition_general.product.description.unit.name : "N/A"}}),
                                  </span>

                                   <span v-if="pog.po_type === 'new_po'">
                                      {{ pog.product ? pog.product.name : "N/A"}} ({{ pog.quantity }} {{ pog.product ? pog.product.description.unit.name : "N/A"}}),
                                  </span>
                                </span>
                            </template>
                          </td>
                          <td>
                            <input type="checkbox"
                                   :checked="selected.includes(item.id)"
                                   @input="check(item.id)"
                            >
                          </td>
                        </tr>
                        </tbody>
                      </table>
                      <p v-if="!poList.length" class="text-center mt-5">No data</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="px-2 mt-3 mb-3">
            <div class="row mb-1 d-flex justify-content-end">
              <div class="col-2">
                <button
                    :disabled="loading"
                    type="button"
                    class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light"
                    @click="submit"
                >
                  <div
                      v-if="loading"
                      class="spinner-border spinner-border-sm text-light"
                      role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
  <GlobalLoader/>
</template>

<script setup>
import TitleButton  from '@/components/atom/TitleButton'
import {ref, inject, reactive, onMounted, computed, watch} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import handleContact from '@/services/modules/contact'
import handleInventory from "@/services/modules/inventory";

const router   = useRouter()
const route    = useRoute()
const supplier = ref(null)
const isOpen   = ref(false);
const loading  = ref(false);
const emit     = defineEmits([
  'onSubmitPOForm', 'onClosePOModal'
])
const showError   = inject('showError');
const showSuccess = inject('showSuccess');
const {fetchContactProfiles} = handleContact()
const {
  fetchAllPOBySupplierId,
} = handleInventory()
const companyId = computed(() => {
  return route.params.companyId
})
const props = defineProps({
  suppliersId: String,
});

let formData = ref({
  supplier_id : null,
});
let headerTitle      = ref('PO List');
let loader   = ref(false)
let poList = ref([])
let contacts = ref([])
let selected = ref([]);
let checkAll = ref(false);

function submit() {
  if (formData.value.supplier_id === null){
    showError("Please select a supplier");
    return;
  }

  if (selected.value.length < 1){
    showError("Please select an PO item");
    return;
  }

  let poData = [];
  Object.values(poList.value).reduce((r, a) => {
    if (selected.value.includes(a.id)){
      poData.push(a);
    }
  }, poData)

  formData.value.poData = poData;
  emit('onSubmitPOForm', formData.value);
}

function toggleModal() {
  isOpen.value = !isOpen.value;
  if (!isOpen.value) {
    resetForm();
    emit("onClosePOModal");
  }
}

function resetForm() {
  formData.value.supplier_id = null;
  poList.value = [];
}

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

function loadSuppliers() {
  loader.value       = true
  const contactRes   = fetchContactProfiles(getQuery())

  Promise.all([
    contactRes.then(res => {
      if (res.data) contacts.value = res.data
    }),
  ])
  .then(() => {
    setData();
    loader.value = false;
  })
  .catch((err) => {
    loader.value = false
  })
}

function setData() {
  resetForm();

  if (props.suppliersId !== null){
    formData.value.supplier_id = props.suppliersId;
    getAllPOBySupplierId();
  }
}

function getAllPOBySupplierId(){
  if (formData.value.supplier_id === null){
    showError("Please select a supplier");
    return;
  }

  const res   = fetchAllPOBySupplierId(formData.value.supplier_id, getQuery())
  Promise.all([
    res.then(res => {
      if (!res.status) {
          poList.value = []
          return
      }
      poList.value = res.data;
    }),
  ])
  .then(() => {
    loader.value = false;
  })
  .catch((err) => {
    loader.value = false
  })
}

function selectAll() {
  selected.value = [];
  if (!checkAll.value) {
    poList.value.forEach((item) => {
      selected.value.push(item.id);
    });
  }
}

function check(id) {
  if (selected.value.includes(id)) {
    selected.value.splice(selected.value.indexOf(id), 1);
  } else {
    selected.value.push(id);
  }

  if (selected.value.length === poList.value.length) {
    checkAll.value = true;
  } else {
    checkAll.value = false;
  }
}

onMounted(async () => {
  formData.value.supplier_id = props.suppliersId;
})

defineExpose({
  toggleModal,
  loadSuppliers,
})
</script>
